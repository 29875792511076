
import AOS from "aos"
import "aos/dist/aos.css"
import { mapGetters } from "vuex"

export default {
  mounted() {
    AOS.init({
      once: true
    });

    const sliderIndexSwiper = new swiper.default(".slider-index .swiper", {
      modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: true,
      slidesPerView: 1,
      watchSlidesProgress: true,
      scrollbar: false,
      speed: 1800,
      autoplay: {
        delay: 10000
      },
      on: {
        sliderMove: this.sliderMove,
        slideChangeTransitionStart: this.slideChangeTransitionStart
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: "true",
        type: "bullets",
        renderBullet: function(index, className) {
          return (
            '<span class="' +
            className +
            '">' +
            '<span class="swiper-pagination-bar"></span>' +
            '<span class="swiper-pagination-progress"></span>' +
            "</span>"
          );
        }
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  methods: {
    sliderMove() {
      document
        .querySelectorAll(".swiper-slide-next .slider-index__circle")
        .forEach(val => {
          val.style.display = "none";
          val.classList.remove("aos-init", "aos-animate");
          val.style.display = "block";
          val.classList.add("aos-init", "aos-animate");
        });
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    }),
    slides() {
      return [
        {
          id: 5,
          heading: `Весенняя распродажа в АЦ «‎Гагарина»`,
          content: `Дополнительная скидка на все автомобили в наличии!`,
          link: "/credit",
          // image: 'back-1'
          image: require('~/assets/img/slider-main/back-5.webp')
        },
        {
          id: 1,
          heading: `Скидка ${this.settings.sale_credit}₽`,
          content: `при оформлении автокредита от ${this.settings.credit_percent}`,
          link: "/credit",
          // image: 'back-1'
          image: require('~/assets/img/slider-main/back-1.webp')
        },
        // {
        //   id: 2,
        //   heading: `Рассрочка 0%`,
        //   content: `на все авто в наличии`,
        //   link: "/installment",
        //   // image: 'back-2'
        //   image: require('~/assets/img/slider-main/back-2.webp')
        // },
        {
          id: 3,
          heading: "Программа Trade-In",
          content: `с выгодой ${this.settings.sale_tradein}₽`,
          link: "/exchange",
          // image: 'back-3'
          image: require('~/assets/img/slider-main/back-3.webp')
        },
      ];
    }
  }
};
